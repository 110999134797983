<template>
  <div class="workbench">
    <div class="message">
      <div class="title">工作台</div>
      <div class="head">
        <!-- <div class="personage">
          <img
            src="https://img1.baidu.com/it/u=1485012388,2380514454&fm=26&fmt=auto&gp=0.jpg"
            alt=""
          />
          <div>
            <p class="p1">{{ base.nickname }},您好</p>
            <p class="p2">欢迎使用平安校园管理系统</p>
            
          </div>
        </div> -->
        <div class="task">
          <!-- @click="drawer_show = true" -->
          <div class="task_title">
            <!-- <el-dropdown placement="bottom">
              <el-badge :value="msgInfo.msg_count">
                <i class="el-icon-message-solid"></i>
                消息
              </el-badge>

              <el-dropdown-menu slot="dropdown">
                <div>未读消息</div>
                <el-dropdown-item
                  v-for="(item, index) in msgInfo.msg_list"
                  :key="index"
                >
                  <div class="dropdown-sty">
                    <p class="p1">
                      {{ item.title }}
                    </p>
                    <p class="p2">
                      {{ item.add_time }}
                    </p>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

            <el-badge :value="taskInfo.task_count">
              <i class="el-icon-s-marketing"></i>
              待办任务
            </el-badge>
            <el-badge :value="flowInfo.flow_count">
              <i class="el-icon-s-custom"></i>
              审批
            </el-badge> -->
          </div>
          <!-- <div class="task_num">
            <div>12</div>
            <div>3 / 14</div>
            <div>1689</div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- <el-drawer style="" title="我是标题" :visible.sync="drawer_show" direction="rtl" >
      <span>我来啦!</span>
    </el-drawer> -->
    <div class="statistics">
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="node">
            <div class="node_name">
              <p>风险点统计</p>
            </div>
            <div class="content">
              <p>
                <countTo
                  :startVal="0"
                  :endVal="danger.total"
                  :duration="duration"
                ></countTo
                >个
              </p>
              <div class="divdata">
                <div
                  v-for="item in danger.color_count"
                  :key="item.id"
                  :style="{ background: item.color }"
                  class="red"
                >
                  <countTo
                    :startVal="0"
                    :endVal="item.total"
                    :duration="duration"
                  ></countTo>
                </div>
              </div>
              <div class="cross_line"></div>
              <div class="result">
                <p>管控比例</p>
                <p>
                  <countTo
                    :startVal="0"
                    :endVal="danger.est_total"
                    :duration="duration"
                  ></countTo>
                  %
                </p>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :span="6">
          <div class="node">
            <div class="node_name">
              <p>隐患统计</p>
              <div class="div0">年</div>
            </div>
            <div class="content">
              <p>
                <countTo
                  :startVal="0"
                  :endVal="hdanger.total"
                  :duration="duration"
                ></countTo>
                个
              </p>
              <div class="divdata">
                <div
                  v-for="item in hdanger.color_count"
                  :key="item.id"
                  :style="{ background: item.color }"
                  class="red"
                >
                  <countTo
                    :startVal="0"
                    :endVal="item.total"
                    :duration="duration"
                  ></countTo>
                </div>
              </div>
              <div class="cross_line"></div>
              <div class="result">
                <p>闭环管理</p>
                <p>
                  <countTo
                    :startVal="0"
                    :endVal="hdanger.percent"
                    :duration="duration"
                  ></countTo
                  >%
                </p>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :span="6">
          <div class="node">
            <div class="node_name">
              <p>安全管控</p>
              <div class="div1">年</div>
            </div>
            <div class="content">
              <p>
                <countTo
                  :startVal="0"
                  :endVal="control.total"
                  :duration="duration"
                ></countTo>
                次
              </p>
              <div class="head_img">
                <p>本月管控</p>
                <p>
                  <countTo
                    :startVal="0"
                    :endVal="control.monthCount"
                    :duration="duration"
                  ></countTo>
                  次
                </p>
              </div>
              <div class="cross_line"></div>
              <div class="result">
                <p>总管控次数</p>
                <p>
                  <countTo
                    :startVal="0"
                    :endVal="control.total"
                    :duration="duration"
                  ></countTo>
                  次
                </p>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :span="6">
          <div class="node">
            <div class="node_name">
              <p>网格化管理</p>
            </div>
            <div class="content">
              <p>
                本月管控
                <countTo
                  :startVal="0"
                  :endVal="netWork.monthCount"
                  :duration="duration"
                ></countTo
                >次
              </p>
              <div class="head_img">
                <p>
                  总管控次数
                  <countTo
                    :startVal="0"
                    :endVal="netWork.control_count"
                    :duration="duration"
                  ></countTo
                  >次
                </p>
              </div>

              <div class="cross_line"></div>
              <div class="result">
                <p>总网格数量</p>
                <p>
                  <countTo
                    :startVal="0"
                    :endVal="netWork.netCount"
                    :duration="duration"
                  ></countTo>
                  次
                </p>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="particulars">
        <div class="control">
          <div class="control_tltie">
            <img
              src="https://img0.baidu.com/it/u=2550813505,1981527801&fm=26&fmt=auto&gp=0.jpg"
              alt=""
            />
            <p>待办任务</p>
          </div>

          <el-table ref="multipleTable" :data="task_List" style="width: 100%">
            <el-table-column label="标题">
              <template slot-scope="scope">
                <el-link
                  style="font-size: 15px"
                  @click="on_particulars(scope.row, 1)"
                  type="primary"
                  >{{ scope.row.instance_title }}</el-link
                >
              </template>
            </el-table-column>
            <el-table-column prop="nickname" label="下发人"></el-table-column>
            <el-table-column
              prop="expire_time"
              label="截止日期"
            ></el-table-column>
          </el-table>
          <div class="paging">
            <el-pagination
              @current-change="way_taskpage"
              :page-size="task_size"
              layout="prev, pager, next, jumper"
              :total="task_total"
              :current-page="task_page"
            >
            </el-pagination>
          </div>
        </div>

        <div class="particulars_message">
          <div class="news">
            <div class="news_tltie">
              <div class="control_tltie">
                <img
                  src="https://img0.baidu.com/it/u=2550813505,1981527801&fm=26&fmt=auto&gp=0.jpg"
                  alt=""
                />
                <p>安全管控动态</p>
              </div>
              <i class="el-icon-s-tools"></i>
            </div>

            <div class="news_table">
              <div
                class="control_List"
                v-for="(item, index) in control_List"
                :key="index"
              >
                <div class="text">
                  <p class="p1">
                    <span class="span1">{{ item.nickname }}</span>
                    <el-link
                      style="font-size: 15px"
                      @click="on_particulars(item, 2)"
                      type="primary"
                      >{{ item.title }}</el-link
                    >
                  </p>
                  <p class="p2">{{ item.add_time }}</p>
                </div>
              </div>
              <div class="paging">
                <el-pagination
                  :pager-count="5"
                  @current-change="way_page"
                  :page-size="page_size"
                  layout="prev, pager, next"
                  :total="total"
                  :current-page="page"
                >
                </el-pagination>
              </div>
            </div>
          </div>

          <div class="news">
            <div class="news_tltie">
              <div class="control_tltie">
                <img
                  src="https://img0.baidu.com/it/u=2550813505,1981527801&fm=26&fmt=auto&gp=0.jpg"
                  alt=""
                />
                <p>隐患日志</p>
              </div>
              <i class="el-icon-s-tools"></i>
            </div>
            <div class="news_table">
              <div
                class="control_List"
                v-for="(item, index) in examine_List"
                :key="index"
              >
                <div class="text">
                  <p class="p1">
                    <span class="span1">{{ item.nickname }}</span>
                    <el-link
                      style="font-size: 15px"
                      @click="on_hidden(item)"
                      type="primary"
                      >{{ item.title }}</el-link
                    >
                  </p>
                  <p class="p2">{{ item.add_time }}</p>
                </div>
              </div>

              <!-- 
              <el-table
                ref="multipleTable"
                :data="examine_List"
                style="width: 100%"
              >
                <el-table-column label="标题">
                  <template slot-scope="scope">
                    <el-link
                      style="font-size: 15px"
                      @click="on_hidden(scope.row)"
                      type="primary"
                      >{{ scope.row.name }}</el-link
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="add_time"
                  label="申请时间"
                ></el-table-column>
              </el-table> -->

              <div class="paging">
                <el-pagination
                  :pager-count="5"
                  @current-change="way_examinepage"
                  :page-size="examine_size"
                  layout="prev, pager, next"
                  :total="examine_total"
                  :current-page="examine_page"
                >
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 查看审批弹窗 -->
      <el-dialog title="审批详情" show-close :visible.sync="examine_pop" center>
        <examineparticulars v-if="examine_pop" ref="examineparticulars" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  contrologindex,
  welcomeindex,
  worktaskuserindex,
  hdangercontroLog,
} from "../../assets/request/api";
import countTo from "vue-count-to";
import control_type from "../../assets/minin/control_type";
import examineparticulars from "../../components/control/examine_particulars";
import axios from "axios";
export default {
  name: "thought_education",
  mixins: [control_type],
  components: { examineparticulars, countTo },
  data() {
    return {
      drawer_show: false,
      duration: 3000,
      //图标数据
      control: "",
      danger: "",
      flowInfo: "",
      hdanger: "",
      netWork: "",
      taskInfo: "",
      msgInfo: "",
      //安全管控动态
      control_List: [],
      page_size: 5,
      page: 1,
      total: 0,
      //代办任务
      task_List: [],
      task_page: 1,
      task_total: 0,
      task_size: 15,

      // 隐患日志
      examine_List: [],
      examine_page: 1,
      examine_total: 0,
      examine_size: 20,

      //个人信息
      base: "",
    };
  },
  created() {
    this.way_Data();
    let base = sessionStorage.getItem("base");
    this.base = JSON.parse(base);
    this.task_From();
    this.examine_From();
    welcomeindex().then((res) => {
      let { control, danger, flowInfo, hdanger, msgInfo, netWork, taskInfo } =
        res.data;
      this.control = control;
      this.danger = danger;
      this.flowInfo = flowInfo;
      this.hdanger = hdanger;
      this.msgInfo = msgInfo;
      this.netWork = netWork;
      this.taskInfo = taskInfo;
    });
  },
  methods: {
    //点击操作详情
    on_particulars(row, on_type) {
      let { task_code, id, source_id, type, info_id } = row;
      let row_type;
      if (on_type == 1) {
        row_type = task_code;
      } else if (on_type == 2) {
        row_type = type;
      }
      let url;
      switch (row_type) {
        case "flow_activity":
        case "activity_recorder":
          url = "/security/case_particulars";
          break;
        case "dangerous_recorder":
        case "dangerous_task":
        case "flow_dangerous":
          url = "/security/risk_particulars";
          break;

        case "personnel_recorder":
        case "flow_personnel":
        case "personnel_task":
          url = "/security/focus_particulars";
          break;
        case "eduction_recorder":
        case "flow_eduction":
        case "eduction_task":
          url = "/security/thought_particulars";
          break;
        case "regulation_recorder":
        case "flow_regulation":
          url = "/security/system_particulars";
          break;
        case "hdanger_recorder":
        case "flow_hdanger":
          url = "/security/Risk_manparticulars";
          break;
        case "case_recorder":
        case "flow_case":
          url = "/security/case_particulars";
          break;
        case "evaluation_recorder":
        case "evaluation_task":
          url = "/assess/review_standingparticulars";
          break;
      }
      let query;

      if (on_type == 1) {
        query = {
          id: source_id,
          type: "task",
        };
        if (url == "/assess/review_standingparticulars") query.task_id = id;
      } else if (on_type == 2) {
        query = {
          id: source_id,
          control_id: info_id,
          type: "control",
        };
        if (url == "/assess/review_standingparticulars") query.task_id = id;
      }

      this.$router.push({
        path: url,
        query,
      });
    },
    //隐患详情
    on_hidden(row) {
      this.$router.push({
        path: "/security/Risk_manparticulars",
        query: {
          id: row.id,
        },
      });
    },
    //待办任务
    task_From() {
      let { task_page, task_size } = this;
      let task_From = {
        page: task_page,
        page_size: task_size,
      };
      worktaskuserindex(task_From).then((res) => {
        let { data, total } = res.data;
        this.task_total = total;
        this.task_List = data;
      });
    },
    way_taskpage(val) {
      this.task_page = val;
      this.task_From();
    },
    //动态管理
    way_Data() {
      let { page_size, page } = this;
      contrologindex({ page_size, page }).then((res) => {
        this.control_List = res.data.data;
        this.total = res.data.total;
      });
    },
    way_page(val) {
      this.page = val;
      this.way_Data();
    },
    //隐患日志
    examine_From() {
      let { examine_page, examine_size } = this;
      let examine_From = {
        page: examine_page,
        page_size: examine_size,
      };
      hdangercontroLog(examine_From).then((res) => {
        let { data, total } = res.data;
        this.examine_total = total;
        this.examine_List = data;
      });
    },
    way_examinepage(val) {
      this.examine_page = val;
      this.examine_From();
    },
  },
};
</script>
<style lang="less" scoped>
@pad: 20px;
@borius: 5px;
.workbench {
  margin: -5px;
}
.title {
  font-weight: 400;
  font-size: 18px;
}
.message {
  padding: @pad;
  background-color: #ffffff;
}
.head {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.personage {
  display: flex;
  img {
    width: 60px;
    height: 60px;
    border-radius: 60px;
  }
  div {
    margin-left: 5px;
    padding: 3px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .p1 {
    font-size: 18px;
    font-weight: bold;
  }
  .p2 {
    font-size: 14px;
    color: #333333;
  }
}
.task {
  & > div {
    display: flex;
  }
}
.statistics {
  margin: 20px;
}
.node {
  border-radius: @borius;

  background-color: #ffffff;
  .node_name {
    height: 30px;
    border-bottom: 1px solid #edeef0;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      height: 25px;
      width: 25px;
      border-radius: 3px;
      text-align: center;
      line-height: 25px;

      font-size: 14px;
    }
    .div0 {
      border: 1px solid #90d4ff;
      color: #369cff;
      background-color: #e6f7ff;
    }
    .div1 {
      border: 1px solid #ffb6b2;
      color: #f42732;
      background-color: #fff1f0;
    }
  }
  .content {
    padding: 20px;
    & > p {
      font-size: 18px;
      font-weight: 600;
      color: #545e6c;
    }

    .divdata {
      display: flex;
      justify-content: space-between;
      div {
        margin-top: 5px;
        color: #ffffff;
        width: 20%;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 3px;
      }
    }
  }
}
.result {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #535b6f;
}
.cross_line {
  width: 100%;
  height: 1px;
  background-color: #edeef0;
  margin: 10px 0;
}
.head_img {
  display: flex;
  margin-top: 5px;
  width: 100%;
  height: 40px;
  img {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    margin-left: -10px;
  }
}
.head_img > :first-child {
  margin-left: 0;
}
.chart_img {
  margin-top: 5px;
  width: 100%;
  height: 40px;
  img {
    width: 100%;
    height: 100%;
  }
}
.particulars {
  border-radius: @borius;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.control {
  width: 70%;
  margin-right: 20px;
  background: #ffffff;
  border-radius: @borius;
}
.control_List {
  // padding: 10px 20px;
  display: flex;
  border-top: 1px solid #e7e9eb;
  img {
    height: 50px;
    height: 50px;
    border-radius: 50px;
  }
  .text {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 0;
  }
  .p1 {
    font-size: 16px;
    color: #4b556a;
    .span1 {
      font-weight: bold;
      margin-right: 5px;
    }
    .span2 {
      margin: 0 5px;
      color: #4c81ea;
    }
  }
  .p2 {
    font-size: 12px;
    color: #9188a8;
  }
}

.control_tltie {
  display: flex;
  align-items: center;
  padding: 20px;

  img {
    height: 30px;
    height: 30px;
    border-radius: 30px;
  }
  p {
    font-size: 18px;
    color: #505a6d;
    margin-left: 10px;
    font-weight: bold;
  }
}
.particulars_message {
  width: 35%;
}
.news {
  border-radius: @borius;
  background: #ffffff;
  margin-bottom: 20px;
}
.news_tltie {
  display: flex;
  align-items: center;
  justify-content: space-between;
  i {
    margin-right: 20px;
    font-size: 20px;
    color: #4d576d;
  }
}
.news_table {
  padding: 0 20px;
}
.task_title {
  div {
    margin-left: 30px;
    color: #475165;
  }
  i {
    color: #3aa0ff;
  }
}
.task_num {
  div {
    margin-left: 15px;
    color: #475165;
  }
}
.paging {
  padding: 10px 0;
  text-align: center;
}
.dropdown-sty {
  width: 300px;
  display: flex;
  justify-content: space-between;
  .p1 {
    flex: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  .p2 {
    text-align: right;
    width: 150px;
  }
}
</style>
