let control_type = {
    data() {
        return {
            examine_pop: false,
            user_defined: false
        }
    },
    methods: {
        on_particulars(row) {
            let { cate } = row
            console.log(row)
            // 1任务  2审批
            if (cate == 1) {
                this.user_defined = true;
                let { info_id, instance_id } = row
                this.$nextTick(function () {
                    this.$refs.customFromanalysis.way_exhibition(info_id, instance_id);
                });
            } else if (cate == 2) {
                this.examine_pop = true;
                let { instance_id } = row
                this.$nextTick(function () {
                    this.$refs.examineparticulars.way_show(instance_id);
                });
            }
        }
    }

}

export default control_type;